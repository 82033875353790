<script setup>
import { computed, watch,ref } from "vue";
import { useStore } from "vuex";
import axios from "axios";

const store = useStore()
const StatusButton = ref(false)
const status = computed(()=>{
  console.log(store.state.UserData.label.length,store.state.UserData.IpShop.length,store.state.selectDataStatus);
  if(store.state.UserData){
    store.state.UserData.label += ''
    store.state.UserData.IpShop += ''
    if(store.state.selectDataStatus)
      if(store.state.UserData.label.length >= 3)
        if(store.state.UserData.IpShop.length >= 3)
          StatusButton.value = true
        else
          StatusButton.value = false
      else
        StatusButton.value = false
    else
      StatusButton.value = false

  }
})
watch(status)

const getCode = async () =>{
  const data = {
    Label: store.state.UserData.label,
    Address: store.state.UserData.address,
    IpShop: store.state.UserData.IpShop,
  }
  let res = await axios.post('https://sponq.ru:3100/api/getnewcode', data)
  console.log(res);
  res = res.data
  if(res.status == 'successful'){
    console.log("dadadadadada");
    store.state.statusCode = true
    store.state.code = res.code
  }
  else
    store.state.error = res
}

const props = defineProps({
  name: {
    type: String,
    default: 'Кнопка'
  },
  className: {
    type: String,
    default: ''
  },
  type: {
    type: String
  },
  customWidth: String
})
</script>

<template>
  <button
      v-if="StatusButton"
      :class="['style-button', `${className}`]"
      :type="type"
      :style="`max-width: ${customWidth}`"
      @click="getCode"
  >{{ name }}</button>
  <p class="scanner__sales-error">{{store.state.error}}</p>
</template>

<style lang="scss">

$this: '.style-button';
#{$this} {
  font-family: var(--font-gpm);
  font-size: 13px;
  line-height: 137%;
  color: #ffffff;
  width: 100%;
  min-height: 46px;
  padding: 14px 16px;
  display: inline-block;
  text-align: center;
  background: var(--color-blue-gray);
  border-radius: 4px;
  transition: all .2s ease-out;

  &--light-gray {
    background: #F5F5F7;
    color: #898A8D;
  }

  &--light-blue {
    background: #90A2B1;
  }
}

</style>

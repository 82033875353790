export default function addVarCss(options) {

    setTimeout(() => {

        /* Example options
        {
            element: 'className',
            coordinateSet: 'h/w/full' (h - Height / w - Width / full - Width and Height )
        }
        */

        let $element = options && options.element ? options.element : 'header';
        let nameVariableCss = options.nameVarCss;
        let coordinateSet = options && options.coordinateSet ? options.coordinateSet : 'h';

        let elementNode = document.querySelector(`.${$element}`);
        let elementCoordinate = elementNode.getBoundingClientRect();

        // console.log(elementCoordinate)

        if(coordinateSet === 'h') {
            document.documentElement.style.setProperty(`--${$element}-vh`, `${elementCoordinate.height}px`);
        } else if(coordinateSet === 'w') {
            document.documentElement.style.setProperty(`--${$element}-vw`, `${elementCoordinate.width}px`);
        } else if(coordinateSet === 'full') {
            document.documentElement.style.setProperty(`--${$element}-vh`, `${elementCoordinate.height}px`);
            document.documentElement.style.setProperty(`--${$element}-vw`, `${elementCoordinate.width}px`);
        }

    }, 300)

};




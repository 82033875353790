<script setup>
import Header from '@/components/header/Header.vue'
import Heading from '@/components/Heading.vue'
const detectDevice = (() => {
  const detectRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i;
  return !detectRegex.test(navigator.userAgent) ? true : false
})()
</script>

<template>
  <Header />
  <div class="scanner" v-if="detectDevice">
  <div  class="scanner__wrapper">
      <Heading
          className="scanner"
          classOther="scanner__heading-content--light"
          :logoHeading="true"
          :contentHeading="[`Эта страница работает только в&nbsp;мобильной версии.`, `Отсканируйте QR-код, чтобы открыть эту страницу на мобильном <br />устройстве.`]"
      />
      <div class="scanner__qr-redirect">
        <img src="@/assets/img/qr-code.svg" alt="QR-Code">
      </div>
    </div>
    </div>
  <router-view v-else />
</template>

<style lang="scss">

// Import Main Style
@import "./styles/main.scss";

#app {
  font-family: var(--font-gp);
  color: #323232;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}
a{
  cursor:pointer
}
input {
  outline: none;
}

nav {
  padding: 23px;
  background: #ffffff;

  a {
    font-weight: bold;
    color: #2c3e50;
    cursor: pointer;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>

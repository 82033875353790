import { createApp } from 'vue'
import {Vuelidate} from "vuelidate";
import App from './App.vue'
import router from './router'
import store from './store'
import addVarCss from '@/assets/libs/utils'

createApp(App).use(store).use(router).mount('#app')

addVarCss({
    element: 'header',
    coordinateSet: 'h'
})

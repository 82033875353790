import { createStore } from 'vuex'
import {ref} from "vue";

export default createStore({
  state: {
    checkInput: ref(''),
    dataAddress: null,
    selectAddress: null,
    UserData: {
      address: '',
      label: '',
      IpShop: ''
    },
    selectDataStatus: true,
    error:'',
    statusCode: false,
    code: ''
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

<script setup>
import debounce from '@/assets/libs/debounce'
import Heading from '@/components/Heading.vue'
import Input from '@/components/scanner/Input.vue'
import Button from '@/components/Button.vue'
import Select from '@/components/Select.vue'
import { useStore } from 'vuex'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
const store = useStore()
const statusCopy = ref()
const copy = (load) =>{
  navigator.clipboard.writeText(load);
  statusCopy.value = true
  setTimeout(() => {
    statusCopy.value = false
  }, 800);
}
const router = useRouter()
</script>

<template>
  <div class="scanner">
    <div class="scanner__wrapper">
      <div class="scanner__step scanner__step--one" v-if="(store.state.statusCode == false)">
        <Heading
            className="scanner"
            :logoHeading="true"
            :contentHeading="[`Получите уникальный номер <br />для промопрограммы Plonq X`,]"
        />
        <div class="scanner__form-wrapper">
          <form
              action=""
              class="scanner__form"
              @submit.prevent.stop=""
          >
            <Input
                wrapperClass="scanner__form-item"
                type="text"
                name="label"
                placeholder="Введите название магазина"
                myError="true"
            />
            <Select />
            <Input
                wrapperClass="scanner__form-item"
                type="text"
                name="ipShop"
                placeholder="Название юридического лица"
                myError="true"
            />
            <div class="scanner__form-item">
              <Button
                  name="Получить код"
                  type="submit"
              />
            </div>
          </form>
        </div>
        <div class="scanner__desc-bottom">
          По любым вопросам обращайтесь к&nbsp;супервайзеру Plonq
        </div>
      </div>
      <div class="scanner__step scanner__step--two" v-else>
        <Heading
            className="scanner"
            :logoHeading="true"
            :contentHeading="[`Ваш уникальный  <br />идентификатор:`,]"
        />
        <div class="scanner__dox-code">
          <div class="scanner__code" @click="copy(store.state.code)">
            <p class="scanner__copy-status" :class="{'scanner__copy-status--active': statusCopy, '':!statusCopy}">Текст скорирован</p>
            <div class="scanner__code-text">
              {{store.state.code}}
              <svg class="icon-copy scanner__copy" width="16px" height="16px">
                <use xlink:href="@/assets/img/sprites.svg#icon-copy"></use>
              </svg>
            </div>
          </div>
<!--          <div class="scanner__code">-->
<!--            <a href="#" class="scanner__copy">-->
<!--              <svg class="icon-copy" width="16px" height="16px">-->
<!--                <use xlink:href="@/assets/img/sprites.svg#icon-copy"></use>-->
<!--              </svg>-->
<!--            </a>-->
<!--            {{store.state.code}}-->
<!--          </div>-->
          <div class="scanner__code-desc">
            Сохраните его и передайте всем продавцам
          </div>
        </div>
        <a @click="router.push({ name: 'home' })" class="style-button scanner__button-forgot">Перейти на главную</a>
<!--        <div class="scanner__desc-bottom scanner__desc-bottom&#45;&#45;black">-->
<!--          Используйте данный код для логина, чтобы сканировать QR-коды на-->
<!--          <strong>скидку&nbsp;500₽ на Plonq X</strong>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>


<style lang="scss">

$this: '.scanner';

#{$this} {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px 0;
  min-height: calc(100vh - var(--header-vh));

  @media (max-width: 767px) {
    height: auto;
  }

  &__forgot {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__copy-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__code-text {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &__copy {
    position: absolute;
    top: 6px;
    right: -32px;
    stroke: #969696;
    cursor: pointer;
  }

  &__copy-status {
    font-size: 13px;
    line-height: 137%;
    color: #a9b5c0;
    position: absolute;
    width: max-content;
    display: inline-block;
    top: -24px;
    left: 50%;
    padding: 3px 10px;
    background: #ffffff;
    box-shadow: 2px 2px 9px rgb(64 95 141 / 10%);
    border-radius: 4px;
    transform: translate(-50%, 10px);
    transition: all .2s ease-out;
    opacity: 0;
    visibility: hidden;

    &--active {
      transform: translate(-50%, 0px);
      opacity: 1;
      visibility: visible;
    }
  }

  &__forgot-link {
    font-family: var(--font-gpl);
    font-size: 15px;
    line-height: 23px;
    color: #657786;
  }

  &__button-forgot {
    margin-top: 30px;
  }

  &__wrapper {
    max-width: 335px;
  }
  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__heading-for-whom {
    font-family: var(--font-gp);
    font-size: 15px;
    line-height: 14px;
    color: #898A8D;
    margin-bottom: 52px;
  }
  &__heading-logo {
    font-size: 45px;

    @media (max-width: 640px) {
      font-size: 31px;
    }
  }
  &__heading-content {
    font-family: var(--font-gpm);
    font-size: 16px;
    line-height: 137%;
    color: var(--color-black);
    text-align: center;
    margin-top: 27px;

    p {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &--light {
      p {
        font-family: var(--font-gp);
      }
    }
  }
  &__heading-code {
    font-family: var(--font-ms);
    font-size: 24px;
    line-height: 137%;
    margin-bottom: 3px;
  }
  &__heading-address {
    font-family: var(--font-gp);
    font-size: 14px;
    line-height: 137%;
    color: #898A8D;
    max-width: 270px;
    margin: 0 auto;
  }

  &__form-wrapper {
    width: 100%;
    max-width: 335px;
    background: #ffffff;
    padding: 20px;
    box-shadow: 2px 2px 9px rgba(64, 95, 141, 0.1);
    border-radius: 7px;
    margin-top: 40px;

    &--qr-code {
      padding-top: 0;
    }
  }

  &__sales-error {
    font-family: var(--font-gp);
    font-size: 12px;
    line-height: 137%;
    color: #FF3B3B;
    text-align: center;
    margin-top: 12px;
  }

  &__form-code-error {
    position: absolute;
    top: 0;
    left: 0;
    font-family: var(--font-gpm);
    font-size: 12px;
    line-height: 137%;
    color: #FF3B3B;
    text-align: center;
    width: 100%;
    padding: 1px 0;
    background: #FFFFFF;
    border-radius: 4px;
    transition: all .2s ease-out;
    transform: translateY(0);
    opacity: 0;
    visibility: hidden;
  }

  &__form-code {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    padding-top: 40px;

    svg {
      fill: #898A8D;
      transition: all .2s ease-out;
    }

    &--error {
      svg {
        fill: #FF3B3B;
      }
      #{$this}__form-code-error {
        transform: translateY(-26px);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__form {

    &-label {
      font-size: 16px;
      line-height: 137%;
      color: #898A8D;
      text-align: center;
      margin-bottom: 20px;
    }

    &-item {
      position: relative;
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }
  }

  &__dox-code {
    background: #FFFFFF;
    border-radius: 7px;
    padding: 42px 25px 47px;
    margin-top: 40px;
    text-align: center;
  }
  &__code {
    position: relative;
    font-family: var(--font-ms);
    font-weight: 500;
    font-size: 44px;
    line-height: 137%;
    color: #90A2B1;
    margin-bottom: 6px;
  }
  &__code-desc {
    font-size: 13px;
    line-height: 137%;
    color: #90A2B1;
  }

  &__desc-bottom {
    font-family: var(--font-gp);
    font-size: 15px;
    line-height: 137%;
    color: #898A8D;
    text-align: center;
    margin-top: 85px;

    &--black {
      color: var(--color-black);
    }
  }

  &__gift {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 7px 0 16px;
    text-align: center;

    p {
      font-size: 13px;
      line-height: 137%;
      color: #898A8D;
      margin-top: 14px;
    }
  }

  &__code-successful {
    text-align: center;
    font-size: 13px;
    line-height: 137%;
    color: #898A8D;
    margin: 5px 0 16px;
  }

  &__qr-redirect {
    width: 268px;
    height: 268px;
    background: #FFFFFF;
    box-shadow: 2px 2px 9px rgba(64, 95, 141, 0.1);
    border-radius: 7px;
    margin: 38px auto 0;
    padding: 26px;

    img {
      width: 100%;
    }
  }

}

</style>

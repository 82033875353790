<script setup>
import { computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex';
import axios from "axios";

import debounce from "@/assets/libs/debounce";
const store = useStore()
const dataAddress = ref([])
const DataForm = ref()
const getData = computed(()=>{
  if(store.state.dataAddress){
    dataAddress.value = store.state.dataAddress
    console.log(dataAddress.value);
  }
})
watch(getData)

const errorobject = reactive({
  address: false
})

let clearData
const status = ref(true)
const getApiAddress = debounce(async(e) => {
  if (status.value) {
    let string = clearData + "";
    if (string.length !== 0) {
      await axios
          .post("https://sponq.ru:3100/api/search", { query: string })
          .then((res) => {
            console.log(res.data.data.length);
            if(res.data.data.length === 0){
              errorobject.address = false
              store.state.dataAddress = clearData
              store.state.selectAddress = clearData
              store.state.UserData.address = clearData
              DataForm.value = clearData
              store.state.selectDataStatus = true
            }
            else{
              errorobject.address = false
              store.state.dataAddress = res.data.data
            }
          });
    }
  }
}, 1500)

const updateAddress = (e) => {
  clearData = e.target.value
  getApiAddress()
}


const props = defineProps({
  name: {
    type: String,
    default: 'Кнопка'
  },
  className: {
    type: String,
    default: ''
  },
  type: {
    type: String
  },
  customWidth: String
})
const loadAddress = (item) => {
  store.state.selectAddress = item.address
  store.state.UserData.address = item.address
  DataForm.value = item.address
  store.state.selectDataStatus = true
}
const activeSearch = ref(false)
watch(activeSearch, ()=>{
  if(activeSearch.value == false)
  store.state.selectDataStatus = true
})
</script>

<template>
  <div class="select-custom scanner__form-item">
    <div :class="['select-custom__head', {'select-custom__head--active': activeSearch} ]">
      <div class="input-style">
        <input
            class="input-style__field"
            type="text"
            name="address"
            placeholder="Введите адрес магазина из списка"
            @input="updateAddress"
            v-model="DataForm"
            @focus="(activeSearch = true)"
            @blur="(activeSearch = false)"
        >
        <button class="input-style__close close" @click="(DataForm = '')">
          <svg class="icon-close" width="6px" height="6px">
            <use xlink:href="@/assets/img/sprites.svg#icon-close"></use>
          </svg>
        </button>
        <div class="input-style__error-message">Данного магазина нет в базе</div>
      </div>
      <div class="select-custom__open" @click="activeSearch = !activeSearch">
        <svg class="icon-arrow-down" width="11px" height="6px">
          <use xlink:href="@/assets/img/sprites.svg#icon-arrow-down"></use>
        </svg>
      </div>
    </div>
    <div class="select-custom__dropdown" :class="{'select-custom__dropdown--active': activeSearch}">
      <div v-if="dataAddress.length" class="select-custom__dropdown-list">
        <div class="select-custom__dropdown-item" v-for="item in dataAddress" :key="item" @click="loadAddress(item)">{{item.address}}</div>
      </div>
      <div v-else class="select-custom__dropdown__placeholder-wrapper">
        <span class="select-custom__dropdown-placeholder"></span>
        <span class="select-custom__dropdown-placeholder"></span>
        <span class="select-custom__dropdown-placeholder"></span>
        <span class="select-custom__dropdown-placeholder"></span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "./../styles/_mixin.scss";

$this: '.select-custom';
#{$this} {

  & .input-style__field:not(:placeholder-shown) + .input-style__close {
    transform: translateX(-46px) translateY(-50%);
  }

  & .input-style__field:not(:placeholder-shown) {
    padding-right: 70px;
  }

  &__open {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 100%;
    background: #F5F5F7;
    border: 1px solid #F5F5F7;
    border-radius: 4px;
    cursor: pointer;
    transition: all .2s ease;

    svg {
      transition: all .2s ease;
    }

    &:hover {
      background: #90A2B1;
      border: 1px solid #90A2B1;

      svg {
        fill: #FFFFFF;
      }
    }
  }

  &__head {
    &--active {
      #{$this}__open {
        background: #90A2B1;
        border: 1px solid #90A2B1;

        svg {
          transform: scale(-1);
          fill: #FFFFFF;
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    padding: 6px 10px 6px 12px;
    background: #FFFFFF;
    border: 1px solid #F5F5F7;
    border-radius: 0 0 4px 4px;
    z-index: 100;
    box-shadow: 2px 2px 9px rgba(64, 95, 141, 0.1);
    transition: all .25s ease-out;
    transform: translateY(16px);
    opacity: 0;
    visibility: hidden;

    &--active {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  &__dropdown-list {
    max-height: 190px;
    padding-right: 10px;
    @include scrollbars(6px, #898A8D, #F5F5F7);
    overflow-y: scroll;
  }

  &__dropdown-item {
    font-size: 10px;
    line-height: 137%;
    min-height: 30px;
    border-radius: 4px;
    padding: 8px 10px;
    transition: all .2s ease-out;
    cursor: pointer;

    &:hover {
      background: #90A2B1;
      color: #FFFFFF;
    }
  }

  &__dropdown__placeholder-wrapper {
    padding: 8px 0;
  }

  &__dropdown-placeholder {
    display: block;
    width: 100%;
    height: 8px;
    border-radius: 20px;
    background: #f5f5f7;

    &:nth-child(1),
    &:nth-child(2) {
      width: 80%;
    }

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

}

</style>

<script setup>
import {ref, watch} from 'vue'
import { useStore } from 'vuex';

const store = useStore()
console.clear()

let clearData

const emit = defineEmits(['update:value'])
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'text'
  },
  className: {
    type: String,
    default: ''
  },
  classActive: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    required: true
  },
  value: {
    type: String,
    default: ''
  },
  wrapperClass: {
    type: String,
    default: ''
  },
  myError: {
    type: Boolean,
    default: false
  }
})
const DataForm = ref()
const Error = ref()
watch(DataForm, ()=>{
  if (DataForm.value.length < 3)
    Error.value = true
  else
    Error.value = false
})
const styleClass = ref()
const updateValue = (e) => {
  emit('update:value', e.target.value)
  clearData = e.target.value
  console.log(e.target.name);
  if(e.target.name === 'label')
    store.state.UserData.label = e.target.value
  if(e.target.name === 'ipShop')
    store.state.UserData.IpShop = e.target.value



  e.target.value.length
      ? styleClass.value = 'input-style--active'
      : styleClass.value = ''
}


const clearValue = () => {
  DataForm.value = ''
}

</script>

<template>
  <div
      :class="['input-style', `${wrapperClass}`, {'input-style--error': Error}]"
  >
    <input
        :class="['input-style__field', className, styleClass]"
        :type="type"
        :name="name"
        :id="name"
        :placeholder="placeholder"
        @input="updateValue"
        v-model="DataForm"
    />
    <button
        class="input-style__close close"
        @click="clearValue"
    >
      <svg class="icon-close" width="6px" height="6px">
        <use xlink:href="@/assets/img/sprites.svg#icon-close"></use>
      </svg>
    </button>
    <div class="input-style__error-message">Данного магазина нет в базе</div>
  </div>
</template>

<style lang="scss">

$this: '.input-style';
#{$this} {
  position: relative;

  &__close {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateX(0) translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background: #F5F5F7;
    border-radius: 3px;
    transition: all .2s ease-out;
    opacity: 0;
    visibility: hidden;
  }

  &__field {
    font-family: var(--font-gp);
    font-size: 13px;
    line-height: 137%;
    color: var(--color-black);
    width: 100%;
    min-height: 46px;
    padding: 10px 16px;
    background: #F5F5F7;
    border: 1px solid #F5F5F7;
    border-radius: 4px;
    transition: all .2s ease;

    &::-webkit-input-placeholder {color: #898A8D;}
    &:-ms-input-placeholder {color: #898A8D;}
    &::-ms-input-placeholder {color: #898A8D;}
    &::placeholder {color: #898A8D;}

    &:focus,
    &--active {
      background: #ffffff;
    }

    &:not(:placeholder-shown) {
      background: #ffffff;

      & + #{$this}__close {
        transform: translateX(-12px) translateY(-50%);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__error-message {
    position: absolute;
    left: 10px;
    top: 0;
    font-size: 10px;
    line-height: 137%;
    color: #FF3B3B;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 1px 7px;
    transform: translateY(0px);
    transition: all .3s ease-out;
    opacity: 0;
    visibility: hidden;
  }

  &--error {
    #{$this}__field {
      background: #FFFFFF;
      border: 1px solid #FF3B3B;
    }
    #{$this}__error-message {
      transform: translateY(-7px);
      opacity: 1;
      visibility: visible;
    }
    & + .select-custom__open {
      background-color: transparent !important;
      border: 1px solid #FF3B3B !important;

      svg {
        fill: #FF3B3B !important;
      }
    }
  }

}
</style>

<script setup>


</script>

<template>
  <header class="header">
    <div class="header__wrapper">
      <div class="header__logo">
        <a class="logo" href="/">
          <span id="main-logo" class="icon-plonq-logo"></span>
        </a>
      </div>
    </div>
  </header>
</template>

<style lang="scss">
$this: '.header';
$scroll: '.header--scroll';

#{$this} {
  -webkit-transform: translateZ(0);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 1000;
  background: #FFFFFF;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .1);
    transition: all ease-out .2s;
    opacity: 0;
    visibility: hidden;
  }

  &--scroll,
  &--active {

    &:before {
      opacity: 1;
      visibility: visible;
    }

  }

  &--offset-eco {
    position: absolute;
    top: 104px;

    @media (max-width: 640px) {
      top: 60px;
    }

    &#{$scroll} {
      position: fixed;
      top: 0;
    }
  }

  &--not-scroll {
    position: absolute;

    &:before {
      display: none;
    }
  }

  &--country-offset {
    @media (max-width: 640px) {
      top: 110px;
    }
  }

  .layout {
    @media (max-width: 1599px) {
      max-width: 1260px;
    }
    @media (max-width: 1024px) {
      max-width: 670px;
    }
    @media (max-width: 640px) {
      width: calc(100% - 100px);
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 15px;
    min-height: 64px;

    max-width: 1400px;
    width: calc(100% - 40px);
    min-width: calc(320px - 40px);
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 1599px) {
      max-width: 1160px;
      padding: 0 20px;
    }

    @media (max-width: 1024px) {
      max-width: 646px;
      padding: 0;
    }

    @media (max-width: 640px) {
      padding: 0;
      min-height: 40px;
      width: 100%;
      max-width: 270px;
    }
  }

  &__location {
    display: none;

    @media (max-width: 1024px) {
      display: block;
    }

    svg {
      @media (max-width: 640px) {
        width: 14px;
        height: auto;
      }
    }
  }

  &__logo {
    position: relative;
    top: 2px;

    .icon-plonq-logo {
      font-size: 20px;
      color: #323232;
      @media (max-width: 1024px) {
        font-size: 28px;
      }
      @media (max-width: 640px) {
        font-size: 14px;
      }
    }
  }

  &__burger {
    position: relative;
    height: 14px;
    width: 14px;
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
    @media (max-width: 640px) {
      .icon-burger {
        width: 15px;
      }
    }

    .burger,
    .close {
      position: absolute;
      display: inline-block;
      font-size: 0;
      transform-origin: 50% 50%;
    }

    .burger {
      &--hide {
        opacity: 0;
        visibility: hidden;
      }
    }

    .close {
      opacity: 0;
      visibility: hidden;
      @media (max-width: 640px) {
        .icon-close {
          width: 14px;
          height: 14px;
        }
      }

      &--show {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__menu {
    @media (max-width: 1024px) {
      display: none;
    }
  }

}


// Menu Header

$menuLink: '.menu__link';
$menuSub: '.menu__sub-wrapper';

.menu {
  display: flex;

  > .mobile-menu__app-link-country {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    cursor: pointer;
    user-select: none;

    > p {
      font-family: var(--font-gp);
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 145%;
      display: flex;
      align-items: center;
      color: #323232;

      &:nth-child(1) {
        > img {
          width: 12px;
          height: 8px;
          margin-left: 5px;
          transform: rotate(0);
          transition: all .2s linear;

          &.active {
            transform: rotate(180deg);
          }
        }
      }
    }

    > ul {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      user-select: none;
      pointer-events: none;
      transition: all 0.3s ease;
      top: calc(100% + 23px);
      transform: translateY(20px);

      right: 0;
      background: #FFFFFF;
      box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.05);
      border-radius: 7px;
      width: 144px;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px 0 10px 0;

      &.active {
        opacity: 1;
        visibility: visible;
        user-select: none;
        pointer-events: all;
        transform: translateY(0);

      }

      > li {
        width: 128px;
        height: 48px;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        align-items: center;
        border-radius: 5px;
        margin: 2.5px 0 2.5px 0;

        &.active {
          background: #EFEFEF;

          > p {
            font-family: var(--font-gpm);
          }
        }

        > p {
          margin-left: 20px;
          font-family: var(--font-gp);
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #323232;
        }

        &:hover {
          background: #EFEFEF;

          > p {
            font-family: var(--font-gpm);
          }

        }
      }
    }
  }

  &__li {
    position: relative;

    &:not(:first-child) {
      margin-left: 120px;

      @media (max-width: 1599px) {
        margin-left: 105px;
      }
    }

    &:hover {
      #{$menuLink} {
        color: #C8BB93;
      }

      #{$menuSub} {
        top: 100%;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__link {
    font-family: var(--font-gp);
    font-size: 13px;
    line-height: 12px;
    color: var(--colorBlack);
    transition: all .2s ease-out;

    @media (max-width: 1599px) {
      font-size: 12px;
    }
  }

  &__sub-wrapper {
    position: absolute;
    left: -20px;
    top: calc(100% + 20px);
    padding-top: 23px;
    transition: all .2s ease-out;
    opacity: 0;
    visibility: hidden;
  }

  &__sub {
    position: relative;
    background: #fff;
    min-width: 306px;
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;

  }

  &__sub-li {
    &:last-child {
      .menu__sub-link {
        padding-bottom: 30px;
      }
    }
  }

  &__sub-link {
    position: relative;
    display: block;
    font-family: var(--font-gp);
    font-size: 16px;
    line-height: 21px;
    color: var(--colorBlack);
    padding: 20px 32px 18px;
    transition: all .2s ease-out;

    &:hover {
      background-color: #f7f7f7;
    }

    span {
      display: block;
      font-family: var(--font-gp);
      font-size: 13px;
      margin-top: 3px;
    }
  }
}

#nav-icon {
  width: 14px;
  height: 14px;
  position: relative;
  user-select: none;
  overflow: hidden;
  cursor: pointer;

  > span {
    width: 14px;
    height: 1px;
    position: absolute;

    &.no {
      &:before {
        position: absolute;
        top: 12px;
        right: 0;
        width: 8px;
        height: 1px;
        background: #000;
        content: "";
        border-radius: 1px;
        transition-duration: .12s;
        transition: transform .12s, top .12s .12s;
      }

      &:after {
        width: 0;
        height: 0;
        background: transparent;
      }
    }

    &:before {
      position: absolute;
      top: 0px;
      right: 0;
      width: 14px;
      height: 1px;
      background: #000;
      content: "";
      border-radius: 1px;
      transition-duration: .12s;
      transition: transform .12s, top .12s .12s;
    }

    &:after {
      position: absolute;
      top: 6px;
      right: 0;
      width: 14px;
      height: 1px;
      background: #000;
      border-radius: 1px;
      content: "";
      transition-duration: .12s;
      transition: transform .12s, top .12s .12s;
    }
  }

  &.open {
    > span {
      &.no {
        //transition: top .12s, transform .12s .12s width .12s;
        transition: all .2s ease-out;
        top: 7px;
        width: 0;
        height: 0;

        &:before {
          height: 0px;
          width: 0px;
        }

        &:after {
          height: 0px;
          width: 0px;
        }
      }

      &:before {
        border-radius: 1px;
        transition: top .12s, transform .12s .12s, width .12s;
        height: 1px;
        width: 23px;
        top: 6px;
        left: -4px;
        transform: rotateZ(-45deg);
      }

      &:after {
        border-radius: 1px;
        transition: top .12s, transform .12s .12s, width .12s;
        width: 21px;
        height: 1px;
        left: -3px;
        top: 7px;
        transform: rotateZ(45deg);
      }
    }
  }
}


$this: '.mobile-menu';
$appItem: '.mobile-menu__app-item';

#{$this} {
  position: absolute;
  top: 100%;
  width: 100%;
  background: #ffffff;
  transition: all .25s linear;
  max-height: 10px;
  // overflow: hidden;
  display: none;
  z-index: 100;
  opacity: 0;
  @media screen and (max-width: 1024px) {
    display: block;
  }

  @media screen and (max-width:640px) {
    height: 100vh;
    box-shadow: none;
    //overflow: hidden;

  }
  @media screen and (max-width:640px) {
    overflow-y: scroll;
  }

  @media screen and (max-width: 640px) {
    z-index: 1100;
  }


  pointer-events: none;
  &--active {
    max-height: 50vh;
    opacity: 1;
    // overflow: auto;
    pointer-events: all;

    // *{
    //   transform: translate(0 0);
    // }
    @media screen and (max-width: 1024px) {
      max-height: var(--fsvh);
      padding-top: 20px;
    }
    @media screen and (max-height: 680px) {
      max-height: var(--fsvh);
    }
  }

  .close {
    position: absolute;
    top: -30px;
    right: 15px;

    .icon-close {
      width: 15px;
      height: 15px;
    }
  }

  &__layout {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 646px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    @media screen and (max-width: 640px) {
      max-width: 300px;
      flex-wrap: wrap;
      border: none;
    }
    @media (max-width: 600px) {
      // padding-top: 30px;
    }
    margin: 0 auto;
    // padding: 0 10px 0 10px;
  }

  &__menu-item {
    max-width: 145px;
    width: auto;
    margin-bottom: 40px;
    margin: 0 0 40px 0;
    min-width: 145px;

    @media screen and (max-width:640px) {
      // padding-left: 15px;
      max-width: 110px;
      min-width: 110px;
      margin: 0;
      margin-bottom: 40px;
    }
    &--offset {
      @media screen and (max-width:640px) {
        // padding-left: 15px;
        max-width: 110px;
        margin: 0;
      }
    }
  }

  &__menu-head {
    font-family: var(--font-gp);
    font-size: calc(13px + 5 * (100vw / 1280));
    line-height: 21px;
    color: #000000;
    margin-bottom: 19px;
  }

  &__menu-li {
    line-height: 17px;


    &:not(:last-child) {
      margin-bottom: 14px;
      @media screen and (max-width:640px) {
        margin-bottom: 7px;
      }
    }
  }

  &__menu-link {
    font-family: var(--font-gp);
    font-size: calc(11px + 3 * (100vw / 1280));
    @media screen and (max-width:640px){
      font-size: 10px;
    }
    line-height: 17px;
    color: #A8A8A8;
    transition: all .2s ease-out;
    &:hover {
      color: #333333;
    }
  }

  &__app {
    max-width: 646px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    margin-top: 40px;
    outline: none;

    @media screen and (max-width:640px) {
      max-width: 300px;
      flex-wrap: wrap;
      margin-top: 0;
      padding-bottom: 30px;
    }
    >.group__btn {
      position: relative;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-tap-highlight-color: transparent;
      >.group__link {
        &.active {
          opacity: 1;
          visibility:visible;
          user-select: none;
          pointer-events: all;
          transform: translateY(0px);
          @media screen and (max-height: 680px) {
            transform: translateY(-165px);
          }
        }
        position: absolute;
        opacity: 0;
        visibility: hidden;
        user-select: none;
        outline: none;
        pointer-events: none;
        transition: all 0.4s ease;
        top: 60px;
        transform: translateY(-40px);
        @media screen and (max-height: 680px) {
          transform: translateY(-100px);
        }
        right: 0;
        background: #FFFFFF;
        box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.05);
        border-radius: 7px;
        width: 144px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 0 10px 0;
        >li{
          width: 128px;
          height: 48px;
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          align-items: center;
          border-radius: 5px;
          margin: 2.5px 0 2.5px 0;
          &.active{
            background: #EFEFEF;
            >a{
              font-family: var(--font-gpm);
            }
          }
          >a{
            margin-left: 12px;
            font-family: var(--font-gp);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #323232;
            display: flex;
            flex-direction: row-reverse;
            >span{
              margin-left: 16px;
            }
          }
          &:hover{
            background: #EFEFEF;
            >a{
              font-family: var(--font-gpm);
            }

          }
        }
      }
      display: none;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
      width: 145px;
      cursor: pointer;
      >p{
        font-family: var(--font-gp);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 145%;
        display: flex;
        align-items: center;
        width: 145px;
        color: #323232;
        margin-bottom: 12px;
      }
      @media (max-width: 1024px) {
        display: flex;
      }
      @media screen and (max-width: 613px) {
        // max-width: 151px;
        max-width: 111px;
        justify-content: flex-start;
        >* {
          @media screen and (max-width: 640px) {
            margin-right: 0;
          }
          margin-right: 5px;
        }
      }
      >span {
        font-size: 13px;
        font-weight: 400;
        font-family: var(--font-gp);
        font-style: normal;
        font-weight: 400;
        padding-top: 3px;
        color: #828282;
        margin-left: 7px;
      }
      >img {
        width: 23px;
        height: 12px;
        &.icon-arrow {
          // opacity: 0.3;
          width: 8px;
          height: auto;
          margin-left: 7px;
          transition: all .3s linear;
          transform: rotate(0deg);
          &.active{
            transform: rotate(180deg);
          }
        }
      }
    }
    >.mobile-menu__app-link-country{
      width: 145px;
      @media screen and (max-width: 640px) {
        width: 111px;
      }
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      cursor: pointer;
      user-select: none;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-tap-highlight-color: transparent;
      >p{
        font-family: var(--font-gp);
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 145%;
        display: flex;
        align-items: center;
        color: #323232;
        margin-bottom: 12px;
        &:nth-child(2){
          >img{
            width: 8px;
            height: auto;
            margin-left: 5px;
            transition: all .3s linear;
            transform: rotate(0deg);
            // order: 2;
            &.active{
              transform: rotate(180deg);
            }
          }
        }
      }
      >ul{
        position: absolute;
        opacity: 0;
        visibility: hidden;
        user-select: none;
        pointer-events: none;
        transition: all 0.4s ease;
        top: 60px;
        transform: translateY(-40px);
        @media screen and (max-height: 680px) {
          transform: translateY(-100px);
        }
        right: 0;
        background: #FFFFFF;
        box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.05);
        border-radius: 7px;
        width: 144px;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 0 10px 0;
        &.active{
          opacity: 1;
          visibility:visible;
          user-select: none;
          pointer-events: all;
          transform: translateY(0);
          @media screen and (max-height: 680px) {
            transform: translateY(-150px);
          }
        }
        >li{
          width: 128px;
          height: 48px;
          display: flex;
          justify-content: flex-start;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          align-items: center;
          border-radius: 5px;
          margin: 2.5px 0 2.5px 0;
          &.active{
            background: #EFEFEF;
            >p{
              font-family: var(--font-gpm);
            }
          }
          >p{
            margin-left: 20px;
            font-family: var(--font-gp);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #323232;
          }
          &:hover{
            background: #EFEFEF;
            >p{
              font-family: var(--font-gpm);
            }

          }
        }
      }
    }
  }

  &__app-item {
    display: flex;
    width: 145px;
    @media screen and (max-width: 640px) {
      margin-bottom: 64px;
      width: 111px;
    }
    &:last-child {
      margin-left: 57px;
    }
  }

  &__app-icon {
    margin-right: 15px;

    &--apple {
      // margin-left: 15px;
    }
    &--android {
      // margin-left: 3px;
    }
    img {
      height: 18px;
    }
  }

  &__app-text {
    font-family: var(--font-gp);
    font-size: 10px;
    line-height: 18px;
    color: #000000;

    p {
      font-family: var(--font-gp);
      font-size: 13px;
      @media screen and (max-width:640px){
        font-size: 10px;
      }
      line-height: 18px;
      color: #000000;
    }

    span {
      display: inline-block;
      font-family: var(--font-gp);
      font-size: 13px;
      @media screen and (max-width:640px){
        font-size: 10px;
      }
      line-height: 18px;
      color: #A8A8A8;
    }

  }
}

</style>
